import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';

import './index.scss';

moment.locale('en');
momentLocalizer();

const AddPricingPlanForm = ({ onSubmit }) => {
  const [name, setName] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [perMinutePrice, setPerMinutePrice] = useState('');
  const [validFrom, setValidFrom] = useState();
  const [validTo, setValidTo] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    validateData();
  }, [basePrice, perMinutePrice, name]);

  const validateData = () => {
    const invalidName = name.length === 0;
    const invalidBasePrice = parseInt(basePrice, 10) !== Number(basePrice);
    const invalidPerMinutePrice = parseInt(perMinutePrice, 10) !== Number(perMinutePrice);
    setDisabled(invalidName || invalidBasePrice || invalidPerMinutePrice);
  };

  const _onSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      name,
      base_price: basePrice,
      per_minute_price: perMinutePrice,
      valid_from: validFrom,
      valid_to: validTo,
    }).then((success) => {
      if (success) {
        reset();
      }
    });
  };

  const reset = () => {
    setName('');
    setBasePrice('');
    setPerMinutePrice('');
    setValidFrom(undefined);
    setValidTo(undefined);
    setDisabled(true);
  };

  return (
    <Fragment>
      <h3>Pricing plans let you assign variable billing to your users. </h3> <h3> Plans can expire after a certain amount of time, uses, both, or never! </h3> <h3>Roamr pricing plans are fully compliant with City Fair Equity programs offering free rides or heavily discounted rentals for those eligible</h3>
      <form onSubmit={_onSubmit} className="add_pricing_plan_form">
        <div className="form_input_section">
          <label className="add_pricing_plan_form__label" htmlFor="name">
            Name
          </label>
          <input
            className="add_pricing_plan_form__data_input"
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(event) => setName(event.target.value.toUpperCase())}
          />
        </div>
        <div className="form_input_section">
          <label className="add_pricing_plan_form__label" htmlFor="base_price">
            Base Price (cents)
          </label>
          <input
            className="add_pricing_plan_form__data_input"
            type="text"
            name="base_price"
            placeholder="100"
            value={basePrice}
            onChange={(event) => setBasePrice(event.target.value)}
          />
        </div>
        <div className="form_input_section">
          <label className="add_pricing_plan_form__label" htmlFor="per_minute_price">
            Per Minute Price (cents)
          </label>
          <input
            className="add_pricing_plan_form__data_input"
            type="text"
            name="per_minute_price"
            placeholder="15"
            value={perMinutePrice}
            onChange={(event) => setPerMinutePrice(event.target.value)}
          />
        </div>
        <div className="form_input_section">
          <label className="add_pricing_plan_form__label" htmlFor="valid_from">
            Valid From (optional)
          </label>
          <DateTimePicker
            className="add_pricing_plan_form__data_input"
            defaultValue={validFrom}
            onChange={(value) => setValidFrom(value)}
          />
        </div>
        <div className="form_input_section">
          <label className="add_pricing_plan_form__label" htmlFor="valid_to">
            Valid To (optional)
          </label>
          <DateTimePicker
            className="add_pricing_plan_form__data_input"
            defaultValue={validTo}
            min={validFrom}
            onChange={(value) => setValidTo(value)}
          />
        </div>
        <input type="submit" disabled={disabled} />
      </form>
    </Fragment>
  );
};

AddPricingPlanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddPricingPlanForm;
