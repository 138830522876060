import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from 'react-table';
import moment from 'moment-timezone';

import { createPricingPlan, getPricingPlans } from '../../state/pricingPlans';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import AddPricingPlanForm from '../../Components/AddPricingPlanForm';

const PricingPlans = ({ actions, pricingPlans, restrictedAccess }) => {
  const [error, setError] = useState();

  useEffect(() => {
    actions.getPricingPlans().catch((e) => setError(e));
  }, []);

  const formatPrice = (amount) => {
    const dollars = Math.floor(amount / 100);
    const cents = `${amount % 100}`.padStart(2, '0');
    return `$${dollars}.${cents}`;
  };

  const onSubmitPricingPlan = (params) => {
    return actions
      .createPricingPlan(params)
      .then(() => actions.getPricingPlans())
      .then(() => true)
      .catch((e) => {
        setError(e);
        return false;
      });
  };

  return (
    <AuthenticatedPage>
      <div className="page-title">Rental Pricing Plans</div>
      {error && <p>{error.message}</p>}
      {!restrictedAccess && <AddPricingPlanForm onSubmit={onSubmitPricingPlan} />}
      {pricingPlans.length > 0 && (
        <div className="user_data">
          <ReactTable
            className="-striped -highlight users_table"
            data={pricingPlans}
            showPaginationBottom={false}
            pageSize={pricingPlans.length}
            columns={[
              {
                Header: 'id',
                accessor: 'id',
                maxWidth: 100,
              },
              {
                Header: 'Name',
                accessor: 'name',
                maxWidth: 300,
              },
              {
                Header: 'Base Price',
                id: 'base_price',
                accessor: (d) => formatPrice(d.base_price),
                maxWidth: 150,
              },
              {
                Header: 'Per Minute Price',
                id: 'per_minute_price',
                accessor: (d) => formatPrice(d.per_minute_price),
                maxWidth: 150,
              },
              {
                Header: 'Minimum Price',
                id: 'minimum_price',
                accessor: (d) => d.minimum_price > 0 && formatPrice(d.minimum_price),
                maxWidth: 150,
              },
              {
                Header: 'Valid From',
                id: 'valid_from',
                accessor: (d) =>
                  d.valid_from &&
                  moment(d.valid_from).tz('America/Los_Angeles').format('MM DD YYYY, h:mm:ss a'),
                maxWidth: 150,
              },
              {
                Header: 'Valid To',
                id: 'valid_to',
                accessor: (d) =>
                  d.valid_to &&
                  moment(d.valid_to).tz('America/Los_Angeles').format('MM DD YYYY, h:mm:ss a'),
                maxWidth: 150,
              },
            ]}
          />
        </div>
      )}
    </AuthenticatedPage>
  );
};

PricingPlans.propTypes = {
  actions: PropTypes.object,
  pricingPlans: PropTypes.array,
  restrictedAccess: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { admin, pricingPlans } = state;
  const restrictedAccess = admin && admin.restricted_access;
  return { pricingPlans, restrictedAccess };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ createPricingPlan, getPricingPlans }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingPlans);
